// import Keen from 'keen-js';
import { EventParserProvider } from '../event-parse/event-parse';
import { HttpClient } from '@angular/common/http';
import { LoggerProvider } from '../logger/logger';
import { LogData } from '../../models/logData';

const KEEN_PROJECT_ID = window['process_env'].KEEN_PROJECT_ID;
const KEEN_WRITE_KEY = window['process_env'].KEEN_WRITE_KEY;
const KEEN_READ_KEY = window['process_env'].KEEN_READ_KEY;
const GTM = window['process_env'].GTM;

export class EventStreamerBase {
  keenClient: any;

  constructor(public dataParser: EventParserProvider, public http: HttpClient, public loggerProvider: LoggerProvider) {
    // this.keenClient = this.initKeenClient();
    dataParser.sessionID = this.getSessionId();
  }

  // initKeenClient() {
  //   return new Keen({
  //     projectId: KEEN_PROJECT_ID,
  //     writeKey: KEEN_WRITE_KEY,
  //     readKey: KEEN_READ_KEY,
  //   })
  // }

  streamEvent(eventType: string, eventData: any) {
    const event = this.dataParser.parseEvent(eventType, eventData);

    // Stream event to the google gtm
    if (GTM) {
      const eventGtm = Object.assign({}, event);
      eventGtm.event = eventType;

      if (window['dataLayer']) {
        window['dataLayer'].push(eventGtm);
      } else {
        window['dataLayer'] = [eventGtm];
      }
    }
    // Stream event to the google keen.io
    event.ipAddress = '${keen.ip}';
    event.userAgent = '${keen.user_agent}';
    // this.keenClient.addEvent(eventType, event);
  }

  streamPurchaseGTMEvent(orderDetails: any, customTitle: string): Promise<any> { //danni
    return new Promise<any>((resolve, reject) => {
      try {
        this.http.get(`/alternproxy/getOrderCount?accountid=${orderDetails.order.accountId}&orderid=${orderDetails.order.id}`).toPromise()
          .then((response) => {
            console.log(`/alternproxy/getOrderCount?accountid=${orderDetails.order.accountId}&orderid=${orderDetails.order.id} success, response: ${JSON.stringify(response)}`);   //danni logging to server
            let newOrExisting = 'unKnown';
            const orderCount = Number(response['count']);
            if (!isNaN(orderCount)) {
              newOrExisting = orderCount > 0 ? 'existing' : 'new';
            } else {
              console.error('Failed to parse response as number:' + response);
              this.loggerProvider.logToServer({
                level: 'error',
                message: `/alternproxy/getOrderCount?accountid=${orderDetails.order.accountId}&orderid=${orderDetails.order.id} reponse is not correct`,
                logObject: { response }
              } as LogData);
            }
            orderDetails.order.newOrExisting = newOrExisting;
            this.streamEvent('purchase', {
              customTitle,
              data: orderDetails.order,
            });
            resolve(orderDetails);
          })
          .catch((error) => {
            this.loggerProvider.logToServer({
              level: 'error',
              message: `/alternproxy/getOrderCount?accountid=${orderDetails.order.accountId}&orderid=${orderDetails.order.id} failed`,
              logObject: { error }
            } as LogData);
            orderDetails.order.newOrExisting = 'unKnown';
            this.streamEvent('purchase', {
              customTitle,
              data: orderDetails.order,
            });
            resolve(orderDetails);
          });

      } catch (error) {
        this.loggerProvider.logToServer({
          level: 'error',
          message: 'streamPurchaseGTMEvent failed',
          logObject: { error }
        } as LogData);
        return resolve(orderDetails);
      }
    });
  }

  getSessionId() {
    let gaId = this.dataParser.cookies.getCookie('GAID');
    if (!gaId) {
      gaId =
        this.s4() +
        this.s4() +
        '-' +
        this.s4() +
        '-' +
        this.s4() +
        '-' +
        this.s4() +
        '-' +
        this.s4() +
        this.s4() +
        this.s4();
      this.dataParser.cookies.setCookie('GAID', gaId, 7);
      this.streamEvent('session', gaId);
    }
    return gaId;
  }

  private s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
}
