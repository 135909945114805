import { EventStreamerBase } from './event-stream-base';
import { HttpClient } from '@angular/common/http';
import { LoggerProvider } from '../logger/logger';

import { Injectable } from '@angular/core';

// import Keen from 'keen-js';
import { EventParserProvider } from '../event-parse/event-parse';

@Injectable({
  providedIn: 'root',
})
export class EventStreamerProvider extends EventStreamerBase {
  constructor(public dataParser: EventParserProvider, public http: HttpClient, public loggerProvider: LoggerProvider) {
    super(dataParser, http, loggerProvider);
  }
}
