import { CookiesProvider } from '../cookies/cookies';

export class EventParserBase {
  sessionID: string;

  constructor(public cookies: CookiesProvider) {}

  parseEvent(eventType, eventData) {
    let productPayload;
    const affiliateInfo = window.sessionStorage.getItem('affiliateInfo');

    if (eventType === 'checkout') {
      productPayload = this.checkout(eventType, eventData);
    } else if (eventType === 'checkoutOption') {
      productPayload = this.checkoutOption(eventType, eventData);
    } else if (eventType === 'funnelOption') {
      productPayload = this.funnelOption(eventType, eventData);
    } else if (eventType === 'purchase') {
      productPayload = this.purchase(eventType, eventData);
    } else if (eventType === 'appScreenView') {
      productPayload = this.screenView(eventType, eventData);
    } else if (eventType === 'session') {
      productPayload = this.session(eventType, eventData);
    }
    if (affiliateInfo) {
      productPayload.affiliateInfo = JSON.parse(affiliateInfo);
    }
    if (productPayload) {
      productPayload.source = window['process_env'].SITE_NAME;
      return productPayload;
    }
  }

  screenView(eventType, eventData) {
    return {
      eventType,
      screenPath: eventData.screenPath,
      screenName: eventData.screenName,
      sessionID: this.sessionID,
    };
  }

  checkout(eventType, eventData) {
    let brand: any;
    if (typeof eventData.data[0].orderFormBrand !== 'undefined') {
      brand =
        typeof eventData.data[0].orderFormBrand.uniqueName !== 'undefined'
          ? eventData.data[0].orderFormBrand.uniqueName
          : '';
    } else if (typeof eventData.data[0].offerBrand !== 'undefined') {
      // tslint:disable-next-line:no-unused-expression
      typeof eventData.data[0].offerBrand.uniqueName !== 'undefined'
        ? eventData.data[0].offerBrand.uniqueName
        : '';
    } else {
      brand = '';
    }
    return {
      eventType,
      customTitle: eventData.customTitle,
      ecommerce: {
        checkout: {
          actionField: {
            step: eventData.step,
            option: eventData.option,
          },
          products: {
            name:
              typeof eventData.data[0].product.name !== 'undefined'
                ? eventData.data[0].product.name
                : '',
            id:
              typeof eventData.data[0].product.sfid !== 'undefined'
                ? eventData.data[0].product.sfid
                : '',
            price:
              typeof eventData.data[0].standardPrice !== 'undefined'
                ? eventData.data[0].standardPrice
                : '',
            brand,
            category: '',
            quantity: 1,
          },
          orderFormData: {
            orderFormName:
              typeof eventData.data[0].name !== 'undefined' ? eventData.data[0].name : '',
            viewType:
              typeof eventData.data[0].viewType !== 'undefined' ? eventData.data[0].viewType : '',
            orderFormType:
              typeof eventData.data[0].formType !== 'undefined' ? eventData.data[0].formType : '',
            route: typeof eventData.data[0].route !== 'undefined' ? eventData.data[0].route : '',
            purchasePrice:
              typeof eventData.data[0].offerPrice !== 'undefined'
                ? eventData.data[0].offerPrice
                : '',
            offerShippingCost:
              typeof eventData.data[0].shippingCost !== 'undefined'
                ? eventData.data[0].shippingCost
                : '',
            subscriptionPrice:
              typeof eventData.data[0].subscriptionPrice !== 'undefined'
                ? eventData.data[0].subscriptionPrice
                : '',
          },
        },
      },
      sessionID: this.sessionID,
    };
  }

  funnelOption(eventType, eventData) {
    return {
      eventType,
      customTitle: eventData.customTitle,
      ecommerce: {
        checkout_option: {
          actionField: {
            step: eventData.step,
            option: eventData.option,
          },
        },
      },
      sessionID: this.sessionID,
    };
  }

  checkoutOption(eventType, eventData) {
    return {
      eventType,
      customTitle: eventData.customTitle,
      ecommerce: {
        checkout_option: {
          actionField: {
            step: eventData.step,
            option: eventData.option,
          },
        },
      },
      sessionID: this.sessionID,
    };
  }

  purchase(eventType, eventData) {
    return {
      eventType,
      customTitle: eventData.customTitle,
      ecommerce: {
        purchase: {
          actionField: {
            id: typeof eventData.data.id !== 'undefined' ? eventData.data.id : '',
            affiliation: 'TouchCR Funnel site',
            revenue: typeof eventData.data.total !== 'undefined' ? eventData.data.total : 0,
            tax: typeof eventData.data.tax !== 'undefined' ? eventData.data.tax : 0,
            shipping:
              typeof eventData.data.orderShippingCost !== 'undefined'
                ? eventData.data.orderShippingCost
                : 0,
            coupon: '',
            newOrExisting: (typeof eventData.data.newOrExisting !== 'undefined') ? eventData.data.newOrExisting : 'unKnown',
          },
          products: [].concat(
            this.getProductsData(eventData.data.orderItems, eventData.data.brand),
          ),
        },
      },
      sessionID: this.sessionID,
    };
  }

  getProductsData(products, brand) {
    const result = [];
    products.forEach((e) => {
      const product = {};
      product['id'] = typeof e.productId !== 'undefined' ? e.productId : '';
      product['name'] = typeof e.productName !== 'undefined' ? e.productName : '';
      product['price'] = typeof e.productPrice !== 'undefined' ? e.productPrice : 0;
      product['brand'] = typeof brand !== 'undefined' ? brand : '';
      product['category'] = '';
      product['imageUrl'] = typeof e.productImage !== 'undefined' ? e.productImage : '';
      product['count'] = typeof e.quantity !== 'undefined' ? e.quantity : 1;
      result.push(product);
    });
    return result;
  }

  session(eventType, eventData) {
    this.sessionID = eventData;
    return {
      eventType,
      sessionID: this.sessionID,
    };
  }
}
